import React from 'react';
import styled, { css } from 'styled-components';

import useProductInfo from 'components/strombestilling/helpers/useProductInfo';
import { BuyButton } from 'components/products/PriceAndBuyBtn';
import {
	ComponentWrapperStyle,
	ComponentWrapperPadding,
} from 'components/ComponentWrapper';
import MaxWidth from 'layouts/max-width';
import Link, { AnchorLink } from 'components/Link';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import ListStyling, { ListWithIconsStyling } from 'libs/SharedStyling';
import BasicContent from 'parts/basic-content/BasicContent';
import SanitizeHtml from 'libs/SanitizeHtml';

const Wrap = styled.div`
	${ComponentWrapperStyle}
	${ComponentWrapperPadding}
`;

const Row = styled.div`
	${p =>
		p.theme.media.large(css`
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		`)}
`;

const Left = styled.div`
	${p =>
		p.theme.media.mediumDown(css`
			margin-bottom: 45px;
		`)}
	button,
	p {
		margin-bottom: 30px;
	}
	button {
		width: 330px;
		max-width: 100%;
		justify-content: center;
	}
	p:last-of-type {
		margin-bottom: 0;
	}
	${p =>
		p.theme.media.large(css`
			width: 58%;
			padding-right: 60px;
		`)}
	small {
		color: ${p => p.theme.colors.grey700};
	}
`;

const Right = styled.div`
	${p =>
		p.theme.media.large(css`
			width: 42%;
		`)}
`;

const PowerProductInfo = styled.div`
	background-color: white;
	box-shadow: ${p => p.theme.utils.boxShadow};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 40px 30px;
	h3 {
		margin-bottom: 10px;
	}
	> p {
		font-weight: 500;
	}
`;

const TermsWrapper = styled.div`
	flex-grow: 1;
	.terms,
	ul {
		width: 100%;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		${p =>
			p.perrow === 1 &&
			css`
				${p =>
					p.theme.media.large(css`
						column-count: 2;
						column-gap: 120px;
						li {
							break-inside: avoid;
						}
					`)}
			`}
	}
	${ListStyling}
	${ListWithIconsStyling}
	.component__basic-content__width-limit {
		padding: 0;
		margin-top: 20px;
		${p =>
			p.theme.media.large(css`
				margin-top: 0;
			`)}
		ul {
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			margin-top: 0 !important;
		}
	}
`;

const Disclaimer = styled.p`
	font-size: 14px;
	line-height: 22px;
	color: ${p => p.theme.colors.grey700};
	margin: 20px 0 0;
	font-weight: 400;
`;

export default function DownpaymentInfo({
	product,
	tariff = 'standardprodukt-inn',
	placement = 'top',
}) {
	const { productInfo } = useProductInfo({ tariff });

	if (!product?.paymentMethods?.includes('Avbetaling (3 år)')) return <></>;

	return (
		<section
			key={`downpayment-information-${placement}`}
			id="CustomComponentDownpaymentInformation"
			data-cy="downpayment-information">
			<Spacing id="avbetaling">
				<Wrap bg="green200">
					<MaxWidth>
						<Row>
							<Left>
								<TitleAndText
									stickTitle="Avbetaling"
									title="For deg som er strømkunde hos NTE"
									spacing={{ bottom: 'none' }}
									nested={true}>
									<>
										<p>
											NTE tilbyr rentefri delbetaling uten
											ekstra kostnad til deg som er
											strømkunde hos oss eller som ønsker
											å bli det. Er du eksisterende
											strømkunde hos oss, kan du beholde
											strømavtalen du allerede har. Blir
											du ny kunde, får du automatisk vår{' '}
											<Link
												to="/strom/stromavtaler/spotpris"
												title="Les mer om vår spotprisavtale">
												spotprisavtale
											</Link>
											.
										</p>
										<p>
											Nedbetalingstiden på produktet er 36
											måneder. Sier du opp strømavtalen
											før de 36 månedene har gått, vil du
											motta en faktura på restbeløpet.
										</p>
										<BuyButton product={product} />
										<p>
											<small>
												Avtalen kan sammenlignes med
												andre avtaler på{' '}
												<AnchorLink
													href="https://strompris.no"
													target="_blank"
													rel="noreferrer"
													title="Sammenlign avtaler på Forbrukerrådets strompris.no">
													Forbrukerrådets
													strømprisportal
												</AnchorLink>
												.
											</small>
										</p>
									</>
								</TitleAndText>
							</Left>
							<Right>
								<PowerProductInfo>
									<h3>Spotpris</h3>
									<p>Følger markedet time for time.</p>
									<TermsWrapper $checkicon="true" perrow={2}>
										<BasicContent
											title={undefined}
											spacing={{
												top: 'none',
												bottom: 'none',
											}}
											settings={[
												'Fullbredde',
												'Skjul tittel og intro',
											]}>
											<SanitizeHtml
												html={
													productInfo?.termsHtml ||
													productInfo?.terms
												}
											/>
										</BasicContent>
										{productInfo?.footnote && (
											<Disclaimer>
												<SanitizeHtml
													html={productInfo?.footnote}
													as="span"
												/>
											</Disclaimer>
										)}
									</TermsWrapper>
								</PowerProductInfo>
							</Right>
						</Row>
					</MaxWidth>
				</Wrap>
			</Spacing>
		</section>
	);
}
